<template>
    <div class="container-action-button">
        <BaseButton @click="onEditCustomerInvoice" buttonText="" title="Modifier la facture">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'search']" />
            </template>
        </BaseButton>
        <BaseButton @click="onDeleteCustomerInvoice" buttonText="" title="Supprimer la facture" v-if="params.data?.pdfGenerated === false && !params.data?.orderDocumentNumber">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'trash']" />
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListCustomerInvoicesActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onEditCustomerInvoice() {
                this.params.context.componentParent.editCustomerInvoice(this.params.data);
            },
            onDeleteCustomerInvoice() {
                this.params.context.componentParent.changeDeleteCustomerInvoice(this.params.data);
            }
        }
    }
</script>