<style scoped>
    #topbar-button-container {
        display: flex;
    }

    #topbar-button-container :deep(button:first-child) {
        margin-right: 20px;
    }
</style>

<template>
    <CustomTopbar>
        <template v-slot:custom-topbar>
            <div id="topbar-button-container">
                <BaseButton buttonText="Créer une facture client" @click="displayCreateCustomerInvoiceModal">>
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'plus']" />
                    </template>
                </BaseButton>
                <BaseButton buttonText="Export Quadra" @click="displayExportCustomerInvoiceModal">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'file-export']" />
                    </template>
                </BaseButton>
            </div>
        </template>
    </CustomTopbar>
    <Aggrid
        apiRoute='orderDocument/list'
        :apiParams="filters"
        :columnDefs="columnDefs"
        :frameworkComponents="frameworkComponents"
        :cellRendererFunctions="cellRendererFunctions"
        :valueFormatterFunctions="valueFormatterFunctions"
        :context="context"
        aggridHeightSubstract="100px"
        @rowClicked="(event) => editCustomerInvoice(event)"
    >
        <template v-slot:filter>
            <div>
                <BaseSelect
                    v-model="monthSearch"
                    label="Mois"
                    name="month"
                    :defaultOptions="months"
                    @onChange="onFiltersChange($event)"
                    :allowEmpty="true"
                />
            </div>
            <div>
                <BaseSelect
                    v-model="yearSearch"
                    label="Année"
                    name="year"
                    :defaultOptions="years"
                    @onChange="onFiltersChange($event)"
                    :allowEmpty="true"
                />
            </div>
            <div>
                <BaseSelect
                    v-model="customerSearch"
                    label="Client"
                    name="customerId"
                    fieldValue="id"
                    fieldLabel="name"
                    @onChange="onFiltersChange($event)"
                    :searchable="true"
                    api="customer/search"
                    :minChars="1"
                />
            </div>
            <div>
                <BaseSelect
                    :defaultOptions="exportedValues"
                    @onChange="onFiltersChange($event)"
                    label="Exporté"
                    name="exported"
                    v-model="exported"
                />
            </div>
        </template>
    </Aggrid>
    <OrderDocumentCreateModal
        mode="invoice"
        orderDocumentRelatedType="quotation"
        title="CRÉER UNE FACTURE CLIENT"
        subtitleDocumentFrom="Basé sur le devis"
        :displayModal="displayModalCreateCustomerInvoice"
        @onOrderDocumentCreated="onCustomerInvoiceCreated"
        @onClose="() => { displayModalCreateCustomerInvoice = false }"
    ></OrderDocumentCreateModal>
    <OrderDocumentModal
        v-if="orderDocuments"
        v-model:orderDocuments="orderDocuments"
        v-show="displayOrderDocumentModal"
        :orderDocumentId="currentInvoiceId"
        @onClose="closeOrderDocumentModal"
        @onOrderDocumentUpdated="onInvoiceUpdated"
    ></OrderDocumentModal>
    <Modal v-show="displayModalExportInvoice">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', 'plus']" />
        </template>
        <template v-slot:modalTitle>
            GÉNÉRER UN EXPORT QUADRA
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseDatepicker
                        label="Mois"
                        @change="updateQuadraExportDate"
                        :onlyMonth="true"
                    ></BaseDatepicker>
                </div>
                <div>
                    <BaseSelect
                        v-model="form.invoiceCurrent"
                        label="Facture client"
                        api="orderDocument/search"
                        :apiParams="{
                            mode: 'invoice',
                            onlyNotExported: true
                        }"
                        fieldValue="id"
                        fieldLabel="label"
                        placeholder="Rechercher"
                        :searchable="true"
                        :minChars="1"
                        @onChange="onInvoiceAdd"
                    />
                </div>
                <div>
                    <ListWithDelete v-model="exportQuadra.invoices" label="Liste des factures client">
                        <template v-slot:content="{ item }">
                            <div>{{ item.label }}</div>
                        </template>
                    </ListWithDelete>
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModalExportInvoice" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="submitModalExportInvoice" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>

    <Dialog ref="dialog" />
</template>

<script>
    import CustomTopbar from '../../components/Topbar/CustomTopbar'
    import BaseButton from '../../components/Base/BaseButton'
    import BaseSelect from '../../components/Base/BaseSelect'
    import Modal from '../../components/App/Modal'
    import ListWithDelete from '../../components/App/ListWithDelete'
    import Aggrid from '../../components/Aggrid/Aggrid'
    import ListTextWithIconRenderer from '../../components/Aggrid/ListTextWithIconRenderer'
    import ListCustomerInvoicesActionButtonsRenderer from './ListCustomerInvoicesActionButtonsRenderer'
    import axios from 'axios'
    import { formatPrice } from '../../utils/formatter'
    import {format, parse} from 'date-fns'
    import { createToast } from 'mosha-vue-toastify'
    import changeTypeEnum from '../../enums/changeTypeEnum'
    import OrderDocumentModal  from '../OrderDocument/OrderDocumentModal';
    import OrderDocumentCreateModal from '../OrderDocument/OrderDocumentCreateModal';
    import BaseDatepicker from "@/components/Base/BaseDatepicker.vue";
    import lastDayOfMonth from "date-fns/esm/lastDayOfMonth/index.js";
    import Dialog from "../../components/App/Dialog.vue"

    export default {
        name: 'CustomerInvoices',
        components: {
            BaseDatepicker,
            CustomTopbar,
            BaseButton,
            BaseSelect,
            Modal,
            ListWithDelete,
            ListTextWithIconRenderer,
            Aggrid,
            OrderDocumentModal,
            OrderDocumentCreateModal,
            Dialog
        },
        data() {
            return {
                columnDefs: [
                    { field: 'createdAt', headerName : `Date`, flex: 1, lockPosition: true, sortable: true, filter: 'agDateColumnFilter' },
                    {field: 'orderDocumentNumber', headerName: `Numéro`, flex: 1, lockPosition: true, sortable: true},
                    {field: 'affairNumber', headerName: `Affaire`, flex: 1, lockPosition: true, customFilter: 'affair.affairNumber'},
                    { field: 'billingName', headerName: `Client`, flex: 1, sortable: true },
                    {
                        field: 'amount',
                        headerName : `Montant`,
                        flex: 1,
                        valueFormatterFunctionName: 'AmountValueFormatterFunction',
                        filter: 'agNumberColumnFilter'
                    },
                    {headerName : `Exporté`, flex: 1, cellRendererFunctionName:'ExportedRendererFunction', suppressMenu: true },
                    { headerName : '', width: 220, cellRenderer: 'ListCustomerInvoicesActionButtonsRenderer', suppressMenu: true }
                ],
                frameworkComponents: {
                    ListCustomerInvoicesActionButtonsRenderer: ListCustomerInvoicesActionButtonsRenderer,
                    ListTextWithIconRenderer: ListTextWithIconRenderer
                },
                cellRendererFunctions: new Map([
                    [
                        'ExportedRendererFunction',
                        function(param) {
                            return param?.data?.metadata?.exported ? 'Oui' : '';
                        }
                    ],
                    [
                        'TypeRendererFunction',
                        function(param) {
                            let type = '';

                            switch (param?.data?.type) {
                                case 'invoice':
                                    type = 'Facture';
                                break;
                                case 'credit':
                                    type = 'Avoir';
                                break;
                            }

                            return type;
                        }
                    ]
                ]),
                valueFormatterFunctions: new Map([
                    [
                        'AmountValueFormatterFunction',
                        function(params){
                            return formatPrice(params?.data?.amount);
                        }
                    ]
                ]),
                context: null,
                displayModalCreateCustomerInvoice: false,
                displayOrderDocumentModal: false,
                formatPrice: formatPrice,
                exportQuadra: null,
                displayModalExportInvoice: false,
                form: {
                    invoiceCurrent: null
                },
                monthSearch: null,
                yearSearch: null,
                customerSearch: null,
                filters: {
                    mode: 'invoice'
                },
                months: [
                    { label: 'Janvier', value: 1 },
                    { label: 'Février', value: 2 },
                    { label: 'Mars', value: 3 },
                    { label: 'Avril', value: 4 },
                    { label: 'Mai', value: 5 },
                    { label: 'Juin', value: 6 },
                    { label: 'Juillet', value: 7 },
                    { label: 'Août', value: 8 },
                    { label: 'Septembre', value: 9 },
                    { label: 'Octobre', value: 10 },
                    { label: 'Novembre', value: 11 },
                    { label: 'Décembre', value: 12 },
                ],
                years: [],
                exported: null,
                exportedValues: [
                    { label: "Oui", value: "yes" },
                    { label: "Non", value: "false" }
                ],
                orderDocuments: null,
                currentInvoiceId: null,
                quadraStartDate: null,
                quadraEndDate: null
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };

            this.resetExportInvoice();

            let years = [];
            for (let year = new Date().getFullYear(); year >= 2019; year--) {
                years.push({
                    label: year,
                    value: year
                });
            }
            this.years = years;
        },
        mounted() {
            if (this.$route.query?.action === 'displayOrderDocument') {
                this.editCustomerInvoice({
                    id: parseInt(this.$route.query.orderDocumentId),
                    affairId: parseInt(this.$route.query.affairId)
                });
            }
        },
        methods: {
            displayCreateCustomerInvoiceModal() {
                this.displayModalCreateCustomerInvoice = true;
            },
            resetExportInvoice() {
                this.exportQuadra = {
                    invoices: []
                }
            },
            displayExportCustomerInvoiceModal() {
                axios
                .get('customer/getCustomerInvoiceByPeriod', {
                    toastError: true,
                    showPreloader: true
                })
                .then((result) => {
                    this.resetExportInvoice();

                    this.exportQuadra.invoices = [];
                    for (let invoice of result.data) {
                        this.exportQuadra.invoices.push({
                            value: invoice.value,
                            label: invoice.label
                        });
                    }

                    this.displayModalExportInvoice = true;
                })
                .catch(() => {});
            },
            onInvoiceAdd(invoice) {
                this.$nextTick(() => {
                    this.form.invoiceCurrent = null;
                });

                // On ajoute la facture client si elle n'est pas déjà présente
                if (invoice.value != null && this.exportQuadra.invoices.filter(element => element.value === invoice.value.value).length === 0) {
                    this.exportQuadra.invoices.push({
                        value: invoice.value.value,
                        label: invoice.value.label
                    });
                }
            },
            closeModalExportInvoice() {
                this.resetExportInvoice();
                this.displayModalExportInvoice = false;
            },
            submitModalExportInvoice() {
                let invoiceIds = this.exportQuadra.invoices.map(invoice => invoice.value);
                if (invoiceIds.length > 0) {
                    this.getExportInvoice(this.exportQuadra.invoices.map(invoice => invoice.value));
                } else {
                    createToast(`L'export doit contenir au moins une facture`, {
                        position: 'bottom-right',
                        type: 'danger',
                        hideProgressBar: true,
                        showIcon: true,
                        transition: 'slide'
                    });
                }
            },
            getExportInvoice(invoiceIds) {
                axios
                .get(`orderDocument/export?invoiceIds=${invoiceIds.toString()}`, {
                    toastError: true,
                    showPreloader: true,
                    responseType: 'blob'
                })
                .then((result) => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(new Blob([result.data]));
                    link.setAttribute('download', `export_factures_client_${format(new Date(), 'ddMMyyyy_HHmmss')}.txt`);
                    document.body.appendChild(link);
                    link.click();

                    this.emitter.emit('ag-grid-reload');
                    this.closeModalExportInvoice();
                })
                .catch(() => {});
            },
            onFiltersChange(input) {
                if (input.changeType === changeTypeEnum.USER) {
                    this.filters[input.name] = input?.value?.value || null;
                }
            },
            onCustomerInvoiceCreated(orderDocuments) {
                this.displayModalCreateCustomerInvoice = false;
                this.orderDocuments = orderDocuments;
                this.displayOrderDocumentModal = true;
                this.emitter.emit('resizeHeight');
            },
            closeOrderDocumentModal() {
                this.displayOrderDocumentModal = false;
            },
            onInvoiceUpdated() {
                this.emitter.emit('ag-grid-reload');
            },
            editCustomerInvoice(invoice) {
                axios
                .get(`orderDocument/getSameOrderDocuments?mode=invoice&${invoice.affairId ? `affairId=${invoice.affairId}` : `orderDocumentId=${invoice.id}`}`, {
                    toastError: true,
                    showPreloader: true
                })
                .then(result => {
                    this.currentInvoiceId = invoice.id;
                    this.onCustomerInvoiceCreated(result.data);
                })
                .catch(() => {});
            },
            changeDeleteCustomerInvoice(invoice) {
                this.$refs.dialog.show({
                    type: 'confirm',
                    title: 'Confirmation',
                    message: `Êtes-vous sûr de vouloir supprimer cette facture ?`
                }).then(() => {
                    axios
                        .put('orderDocument/changeDeleted/' + invoice.id, {
                            deleted: !invoice.deleted
                        }, {
                            toastSuccessMessage: `Modification effectuée`,
                            toastError: true,
                            showPreloader: true
                        })
                        .then(() => {
                            this.emitter.emit('ag-grid-reload')
                        });
                })
                    .catch(() => {});
            },
            updateQuadraExportDate(event) {
                let selectedMonth = event?.target?.value;
                if (selectedMonth == null || selectedMonth === '') return;

                let date = parse(selectedMonth, 'MM/yyyy', new Date())

                this.quadraStartDate = format(date, "dd/MM/yyyy");
                this.quadraEndDate = lastDayOfMonth(date) && format(lastDayOfMonth(date), "dd/MM/yyyy")

                const startCondition = (this.quadraStartDate !== null ? `?quadraStartDate=${this.quadraStartDate}` : '')
                const endCondition = (this.quadraEndDate !== null ? (startCondition !== '' ? '&' : '?') + `quadraEndDate=${this.quadraEndDate}` : '')
                axios
                    .get(`customer/getCustomerInvoiceByPeriod${startCondition}${endCondition}` , {
                        toastError: true,
                        showPreloader: true
                    })
                    .then(response => {
                        this.exportQuadra.invoices = response.data
                    })
                    .catch(() => {});
            }
        }
    }
</script>